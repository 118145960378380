<template>
  <van-uploader
    accept="image/png, image/jpeg,image/jpg"
    :after-read="afterRead"
  >
    <van-icon class="evaluateTopImgBtnIcon" name="plus" />
    <span>添加图片</span>
  </van-uploader>
</template>

<script>
import doctorImgUpdate from '../services/update.js'
export default {
  data() {
    return {
      file_list: []
    }
  },
  methods: {
    afterRead(res) {
      console.log(res)
      const formData = new FormData()
      formData.append('file', res.file)
      doctorImgUpdate(formData).then(res => {
        console.log(res)
      })
    }
  }
}
</script>

<style scoped>
.back{
  z-index: 100;
}
</style>
